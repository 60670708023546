// 我加入的企业
// mxy
<template>
  <div class="main flex-column" :style="{ minHeight: minHeight + 'px' }">
    <div class="list">
      <a-spin :spinning="spinning">
        <a-list item-layout="vertical" :data-source="list">
          <template #renderItem="{ item }">
            <a-list-item>
              <template #actions>
                <div>
                  {{ item.isCreator == false ? "我加入的" : "我创建的" }}
                </div>
                <div>{{ item.joinTimeS }}</div>
              </template>
              <template #extra>
                <div
                  class="flex-column align-end justify-around"
                  style="height: 100%"
                >
                  <div class="flex">
                    <div class="now" v-if="item.isLookUp">当前企业</div>
                    <div
                      @click="outEnterprise(item)"
                      class="out"
                      v-if="item.isCreator == false"
                    >
                      退出企业
                    </div>
                  </div>
                </div>
              </template>
              <a-list-item-meta :description="item.enterpriseCode">
                <template #title>
                  {{ item.enterpriseName }}
                  <a-tooltip>
                    <template #title>{{ item.versionMembers }}</template>
                    <img
                      :src="item.versionIcon"
                      v-if="item.versionIcon != ''"
                      class="icon"
                    />
                  </a-tooltip>
                </template>
              </a-list-item-meta>
            </a-list-item>
          </template>
        </a-list>
      </a-spin>
    </div>
  </div>
  <a-modal
    v-model:visible="visible"
    :title="title"
    ok-text="确认"
    :maskClosable="false"
    cancel-text="取消"
    @ok="handleLogout"
    :confirmLoading="confirmLoading"
    :closable="false"
  >
    <a-spin :spinning="spinning">
      <div class="refresh">是否确定退出当前企业</div>
    </a-spin>
  </a-modal>
  <a-modal
    v-model:visible="visibleOut"
    :title="title"
    ok-text="确认"
    :maskClosable="false"
    cancel-text="取消"
    footer
    :closable="false"
  >
    <a-spin :spinning="spinning">
      <div class="refresh">您已退出当前企业,需重新登录</div>
      <div class="handle_logout">
        <a-button type="primary" @click="okLogout"> 确定 </a-button>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { minheight } from '../../components/sameVariable'

export default defineComponent({
  data () {
    return {
      spinning: false, // 是否加载
      list: [], // table数据
      visible: false,
      visibleOut: false,
      title: '退出企业',
      selectData: {},
      confirmLoading: false,
    }
  },
  created () {
    this.minHeight = minheight
    this.getUserEnterprise()
  },
  methods: {
    // 已加入企业
    getUserEnterprise () {
      this.spinning = true
      api
        .get('/api/app/app-profile/user-tenants-and-version')
        .then(({ data }) => {
          this.spinning = false
          this.list = data
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取用户企业信息失败')
          console.log(err)
        })
    },
    outEnterprise (item) {
      this.selectData = item
      this.visible = true
    },
    handleLogout () {
      this.spinning = true
      this.confirmLoading = true
      api
        .post('/api/app/app-profile/exit-tenant', {
          tenant: this.selectData.tenantId,
        })
        .then(({ data }) => {
          this.visible = false
          this.spinning = false
          this.confirmLoading = false
          this.$message.success('成功退出企业')
          if (!this.selectData.isLookUp) {
            this.getUserEnterprise()
            var url = window.location.href
            window.location.href = url
            // location.reload()
          } else {
            this.visibleOut = true
            window.localStorage.clear()
            // 清除cookie
            window.document.cookie = 'userName' + '=' + '' + ';path=/;expires='
            window.document.cookie = 'userPwd' + '=' + '' + ';path=/;expires='
            history.pushState(null, null, document.URL)
            window.addEventListener(
              'popstate',
              function (e) {
                history.pushState(null, null, document.URL)
              },
              false,
            )
          }
        })
        .catch(err => {
          this.visible = false
          this.spinning = false
          this.confirmLoading = false
          // this.$message.error('获取用户企业信息失败')
          console.log(err)
        })
    },
    okLogout () {
      this.spinning = true
      window.location.href = '/account/login'
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.main {
  background: #fff;
}
.list {
  .now {
    padding: 3px 5px;
    background: #e7ecf8;
    color: #004dca;
    border: 1px solid #004dca;
  }
  .out {
    padding: 3px 5px;
    background-color: #004dca;
    color: #ffff;
    border: 1px solid #004dca;
    cursor: pointer;
    margin-left: 20px;

    &:hover {
      opacity: 0.7;
    }
  }
  .icon {
    margin-left: 20px;
    height: 30px;
    width: 30px;
  }
}
.refresh {
  padding: 13px 0 30px 0;
  font-size: 16px;
}
.handle_logout {
  position: relative;
  left: 88%;
}
</style>
